body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer:hover {
  cursor: pointer;
}

.error-txt {
  color: red;
}

.dashboard-content-container p:not(:first-child) {
  border-top: 1px solid black;
}

.dashboard-content-container p {
  padding: 7px 0px 7px 0px;
  margin: 0;
}

.dialog-data-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.dialog-data-field:hover {
  background-color: aliceblue;
}

.text-end-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
}
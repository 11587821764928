.list-col1 {
    flex: 2 0 130px;
    flex-wrap: nowrap;
}
.list-col2 {
    flex: 2 0 150px;
    flex-wrap: nowrap;
}
.list-col3 {
    flex: 1 0 150px;
    flex-wrap: nowrap;
}
.list-col4 {
    flex: 0 0 200px;
    flex-wrap: nowrap;

}

.key-btns {
    margin-left: 2px;
    margin-right: 0;
}

.list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 30px;
    border: 2px solid rgb(184, 184, 184);
    min-width: 800px;
    max-width: 1100px;
    margin-top: 20px;
}
.expansion-container {
    border: 2px solid rgb(184, 184, 184);
    margin: 10px 0px;
    max-width: 1100px;
    min-width: 800px;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
}
.list-element-btns {
    display: flex;
    justify-content: flex-end;
    transform: translateY(-2px);
}
.list-element {
    display: flex;
    flex-direction: row;
    border-top: 1px solid;
    padding: 10px 0px;
}
.list-element-clickable {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}
.list-element-clickable:hover {
    background-color: rgb(231, 231, 231);
    cursor: pointer;
}
.list-element-header {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
}
.content-container {
  margin: 20px 5vw 0px 40px;
}

.expanded-text-element {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-right: 25px;
}